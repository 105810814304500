(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal','$translate', 'Store','AuthServerProvider','Module', '$state', '$timeout', 'Auth', '$rootScope', 'MessageService', '$localStorage','$http'];

    function HomeController($scope, Principal,$translate, Store,AuthServerProvider,Module, $state, $timeout, Auth, $rootScope, MessageService, $localStorage,$http) {
        var vm = this;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.phoneNumber = null;
        vm.password2 = null;
        vm.Module = Module
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        $(".wrap").hide();
        $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        // 点击登录事件
        function login(event) {
            if ($scope.tab) {
                vm.UserNameLogin(event)
            } else {
                vm.MobileLogin(event)
            }
        }
        vm.UserNameLogin = function login(event) {
            if (vm.username == null || vm.username == '') {
                MessageService.error("请输入用户名");
                return;
            }
            if (vm.password == null || vm.password == '') {
                MessageService.error("请输入密码");
                return;
            }
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                goHome()
            }).catch(function (error) {
                if (error && error.data && error.data["com.deltazo.westeros.security.UserNotActivatedException"]) {
                    MessageService.error("用户未激活");
                } else {
                    MessageService.error("用户名或密码不正确");
                }
                vm.authenticationError = true;
            });
        }
        // 手机号登录
        vm.MobileLogin = function (event) {

            if (vm.phoneNumber == null) {
                MessageService.error("请输入手机号");
                return;
            }
            if (vm.phoneNumber.length != 11) {
                MessageService.error("手机号应为11位");
                return;
            }
            if (vm.password2 == null || vm.password2 == '') {
                MessageService.error("请输入密码");
                return;
            }
            event.preventDefault();
            $http.post('security/api/authenticate2', {
                phone: vm.phoneNumber,
                password: vm.password2,
                rememberMe: vm.rememberMe
            }).success(function (data, status, headers) {
                if (data.status==400) {
                    MessageService.error('账号或密码有误');
                    return;
                }
                 var bearerToken = headers('Authorization');
                if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                    var jwt = bearerToken.slice(7, bearerToken.length);
                    AuthServerProvider.storeAuthenticationToken(jwt, vm.rememberMe);
                    Principal.identity(true).then(function (account) {
                        if (account !== null) {
                            $translate.use(account.langKey).then(function () {
                                $translate.refresh();
                            });
                        }
                        goHome()
                    });
                }
            })
        }
        function goHome() {
            Store.getCurrent(function (res) {
                if (res.status == 200) {
                    if (!res.data.activated) {
                        MessageService.info("请等待平台管理员审核");
                        return;
                    }
                    var _route = findFirstMatchingMenu(vm.Module,$localStorage.user.authorities)
                    $state.go(_route.id);
                } else {
                    MessageService.error(res.message);
                }
            });
            vm.authenticationError = false;
            $(".wrap").show().css({ margin: '48px 10px 10px 200px' });
            delete $localStorage.menu1;
            delete $localStorage.menu2;
            delete $localStorage.pageName;
            $rootScope.$broadcast('authenticationSuccess');
            if (Auth.getPreviousState()) {
                var previousState = Auth.getPreviousState();
                Auth.resetPreviousState();
                $state.go(previousState.name, previousState.params);
            }
        }

        function findFirstMatchingMenu(a, b) {
            for (var i = 0; i < a.length; i++) {
                var item = a[i];
                for (var j = 0; j < item.menus.length; j++) {
                    var menu = item.menus[j];
                    for (var k = 0; k < menu.auth.length; k++) {
                        if (b.indexOf(menu.auth[k]) !== -1) {
                            return menu;
                        }
                    }
                }
            }
            return null;
        }


        function register() {
            $state.go('register');
        }
        function requestResetPassword() {
            $state.go('requestReset');
        }
        $scope.tab = true
        // tab栏切换
        $scope.func = function (param) {//tab切换的事件
            if (param == 1) {
                $scope.tab = true;
            } else if (param == 2) {
                $scope.tab = false;
            }
        }
    }
})();
