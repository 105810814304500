(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductDialogCreateController', ProductDialogCreateController);

    ProductDialogCreateController.$inject = ['$timeout', '$scope', '$state',   '$q', 'entity', 'Product', 'Category', 'ListService', 'UidService',
        'MessageService', 'Qinius', 'ProductXmlLines', 'ProductXmlFileServes','$uibModal', 'Music', 'Mould','PlatformSetting','Store',
        'DiyEditorSetting', 'TaskbillForms', "ProductXmls"
    ];

    function ProductDialogCreateController($timeout, $scope, $state, $q, entity, Product, Category, ListService, UidService,
        MessageService, Qinius, ProductXmlLines, ProductXmlFileServes,$uibModal, Music, Mould, PlatformSetting, Store,
         DiyEditorSetting, TaskbillForms, ProductXmls
    ) {
        var vm = this;
        localStorage.setItem('Production', 'edit');
        $scope.status = localStorage.getItem('Production');
        vm.product = entity.data;
        // vm.product.fileFormat='PDF';
        vm.clear = clear;
        vm.save = save;
        vm.search = search;
        vm.pTicketCheck = pTicketCheck;
        vm.datePickerOpenStatus = {};
        vm.properties=[];
        vm.xmlType='XML';
        vm.title = "编辑"
        if(!vm.product.id){
            vm.title = "新建"
        }else if(vm.product.bokehType!="None"){
            vm.product.bokeh = true;
        }
        DiyEditorSetting.getAllByCondition({
            items: [{key: "cancelled", op: "=", value: "false"}]
        }, function (res) {
            vm.diyEditorSettings = res.data;
        });


        vm.multilingual = multilingual;
        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.product.productLanguages,
                            key:"name",
                            fieldValue:vm.product.name,
                            fieldName:"商品名称"
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    vm.product.productLanguages=data;
                }
            });
        }


        vm.multilingual2 = multilingual2;
        function multilingual2(item){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:item.productXmlLineLanguages,
                            key:"name",
                            fieldValue:item.name,
                            fieldName:"模板展示名称"
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    item.productXmlLineLanguages=data;
                }
            });
        }

        vm.currentXmlLine = {};
        vm.domains = Qinius.getDomain();
        vm.openCalendar = openCalendar;

        vm.store = Store.getCurrent();

        vm.categoriesAll =  Category.storeGetAllByCondition({
            items: [{key: "usable", op: "=", value: "true"}]
        });

        vm.platformSetting = PlatformSetting.get();

        vm.propertyShow = false;
        vm.xmlShow = false;

        vm.editorTypes = [];

        $scope.editorTypeChange = function(){
            if (vm.product.editorType != 'NEW_EDITOR') {
                vm.product.forceFill = false;
                vm.product.aiFill = false;
            }
            if (vm.product.editorType != 'PICTURE' || vm.product.editorType != 'PICTURE_PRO') {
                vm.product.jhiPackage = false;
            }
        }

        $scope.productTypeChange = function(){
            if (vm.product.productType == 'BOOK') {
                vm.editorTypes = [{name:'相册编辑器',value:'NEW_EDITOR'}];
            }
            if (vm.product.productType == 'CALENDAR') {
                vm.editorTypes = [{name:'相册编辑器',value:'NEW_EDITOR'},{name:'动态台历编辑器',value:'CALENDAR_EDITOR'}];
            }
            if (vm.product.productType == 'PHOTO') {
                vm.editorTypes = [ {name:'冲印编辑器',value:'PICTURE'}, {name:'冲印编辑器Pro',value:'PICTURE_PRO'}, {name:'证件照编辑器',value:'PHOTO_EDITOR'}];
            }
            if (vm.product.productType == 'THING') {
                vm.editorTypes = [{name:'万物编辑器',value:'POSTER'},
                {name:'模块化编辑器',value:'DIY_EDITOR'}, {name:'表单编辑器',value:'FORM_EDITOR'}];
            }
            if (vm.product.productType != 'BOOK') {
                vm.product.bindingType = 'Left';
            }
        }

        $scope.aiFillChange = function(){
            if (vm.product.aiFill) {
                vm.product.forceFill = false;
            }
        }

        $scope.forceFillChange = function(){
            if (vm.product.forceFill) {
                vm.product.aiFill = false;
            }
        }


        $scope.showTree = function(tree){
            tree.show = !tree.show;
        }

        $scope.productXmlLineCopy = function(itemValue, line){
            var productXmlLine = angular.copy(line);
            productXmlLine.uuid= UidService.get();
            productXmlLine.id = null;
            itemValue.productXmlLines.push(productXmlLine);
        }

        $scope.productXmlLineDelete = function(itemValue, line){
            if (itemValue.productXmls) {
                angular.forEach(itemValue.productXmls, function (p) {
                    if (p.id == line.productXmlId) {
                        p.select = false;
                        p.choose = false;
                    }
                });
            }
            if (!!line.id) {
                line.cancelled = true;
            } else {
                var ind = itemValue.productXmlLines.indexOf(line);
                if (ind >= 0) {
                    itemValue.productXmlLines.splice(ind, 1);
                }
            }
        }

        window.xmlLineProductFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id;
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get()+".pdf");
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.fileIdentifier =  $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.itemvalueDefault =  function(itemValue){
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValues[i];
                if (value.uuid == itemValue.uuid) {
                    continue;
                }
                value.default = false;
            }
        }

        $scope.amountChange = function (itemValue, amount) {
            if (itemValue == null || itemValue[amount] == null) {
                return;
            }
            if (!(/^-?\d+\.?\d{0,2}$/.test(itemValue[amount]))) {
                MessageService.error("金额只能输入小数点后两位数");
                itemValue[amount] = Math.round(itemValue[amount] * 100) / 100;
                return;
            }
        };

        $scope.addItemValue = function () {
            if (vm.product.itemValues == null) {
                vm.product.itemValues = [];
            }
            if (vm.product.defaultObjectProperties == undefined) {
                MessageService.error("请选择规格");
                return;
            }
            var itemValue = { uuid: UidService.get(), objectValues: []};
            for (var i = 0; i < vm.product.defaultObjectProperties.length; i++) {
                var op = vm.product.defaultObjectProperties[i];
                var objectValue = {
                    seq: op.seq,
                    uuid: UidService.get(),
                    propertyValues: op.propertyValues,
                    propertyId: op.propertyId,
                    propertyName: op.propertyName
                };
                itemValue.objectValues.push(objectValue);
            }
            vm.product.itemValues.push(itemValue);
        }

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });

        vm.music = Music.allByCondition({});
        $q.all([vm.domains.$promise, vm.product.$promise, vm.categoriesAll.$promise, vm.platformSetting.$promise, vm.store.$promise]).then(function () {
            vm.store = vm.store.data;
            vm.platformSetting = vm.platformSetting.data;
            vm.music = vm.music.data;
            vm.categories = vm.categoriesAll.data;
             $scope.productTypeChange();
            vm.forms = TaskbillForms.findAll({ items:[{key: 'storeId', op: '=', value: vm.store.id}] });
            Mould.getAllByCondition({items: [{key:"enable", op:"=", value:"true"}, {key: "storeId", op: "=", value: vm.store.id}]},function (res) {
                if(res.status == 200){
                    vm.moulds = res.data;
                }else{
                    MessageService.error(res.message);
                }
            });

            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            if (vm.product.id) {

                if (vm.product.itemValues && vm.product.itemValues.length>0) {
                    for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                        var itemValue = vm.product.itemValues[i];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        if (itemValue.productXmlLines && itemValue.productXmlLines.length > 0) {
                            itemValue.productXmlLines.sort(function(a, b){
                                return a.seq - b.seq;
                            })
                        }
                    }
                    $scope.selectItemValue = vm.product.itemValues[0];
                }
                if (vm.product.objectProperties !=null) {
                    vm.product.defaultObjectProperties = [];
                    for (var i = vm.product.objectProperties.length - 1; i >= 0; i--) {
                        var ob = vm.product.objectProperties[i];
                        vm.properties.push(ob);
                        vm.product.defaultObjectProperties.push(ob);
                    }
                }
                getProductXmls();
            }
        }).then(function () {
        });


        $scope.getObjectPropertyValue = function (op) {
            if (vm.product.itemValues == null || op == null) {
                return;
            }
            var result = ListService.inList2(op, vm.propertiesBk, "propertyId", "id");
            if (result) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var iv = vm.product.itemValues[i];
                    var value = ListService.inList2(result, iv.objectValues, "id", "propertyId");
                    if (value != null) {
                        return value;
                    }
                }
            }

            return null;
        }
        function pTicketCheck(b){
            vm.product.pTicket = b;
        }

        $scope.removeFile = function (field) {
            vm.product[field] = null;
        };

        $scope.removeFile2 = function (itemValue) {
            itemValue.coverImg = null;
        };
        $scope.removeProjectImg = function(){
            vm.product.projectImg = null;
        }

        //产品 coverImg更改
        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.coverImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        //冲印编辑图片上传
        window.updateEditTipPic = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.editTipPic = $scope.domain + data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.uploadFile = function (fileWatched, uuid) {
            $timeout(function () {
                var file = fileWatched;
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        // 模板封面图片修改
        window.xmlLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.coverImg = $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        // itemvalue封面图片修改
        window.itemvalueLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled ) {
                                continue;
                            }
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.goToView = function (line) {
            console.log(line.productXmlIdentifier)
            if(!line.productXmlIdentifier){
                MessageService.error('请选择模板');
                return
            }
            if (!vm.product.bindingType) {
                MessageService.error('请设置翻页方式');
                return
            }
            createModal('app/entities/product/product-view-article.html', 'ProductViewArticleController',line.productXmlIdentifier).result.then(
                function (data) {

                });
        };

        var createModal = function (url, controller,fileName) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',

                resolve: {
                    entity: {
                        fileName: fileName,
                        bindingtype:vm.product.bindingType,
                        product:vm.product,
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }]
                }
            });
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("product");
        }

        $scope.validate = function () {
            if (vm.product.name == null || vm.product.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            if (vm.product.categoryId == null || vm.product.categoryId == "") {
                MessageService.error("请选择分类");
                return false;
            }

            if (vm.product.fileFormat != 'PDF') {
                vm.product.flatten = false;
            }

            if(vm.product.editorType == 'PICTURE'){
                if(vm.product.editTip && !vm.product.editTipPic){
                    MessageService.error("编辑提示图片不能为空");
                    return false;
                }
            }

            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var item = vm.product.itemValues[i];
                if (item.cancelled) {
                    continue;
                }

                for (var j = item.objectValues.length - 1; j >= 0; j--) {
                    var value = item.objectValues[j];
                    if (value.propertyValueId == null || value.propertyValueId == "") {
                        MessageService.error("组合中，请选择规格值");
                        return false;
                    }
                }

                if (item.productXmlLines && item.productXmlLines.length>0) {
                    for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                        var xml = item.productXmlLines[j];
                        if (xml.cancelled) {
                            continue;
                        }
                        if (xml.name == null || xml.name == "") {
                            MessageService.error("请为模板设置展示名称");
                            return false;
                        }
                        if (xml.coverImg == null || xml.coverImg == "") {
                            MessageService.error("请为模板设置展示图");
                            return false;
                        }
                        if (xml.productXmlId == null || xml.productXmlId == "") {
                            MessageService.error("请选择模板");
                            return false;
                        }
                    }
                }
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            if (vm.product.bokeh == null || vm.product.bokeh == false) {
                vm.product.bokehType = "None";
            }
            vm.product.objectProperties = [];
            if (vm.product.defaultObjectProperties &&  vm.product.defaultObjectProperties.length>0 ) {
                for (var i = vm.product.defaultObjectProperties.length - 1; i >= 0; i--) {
                    var op = vm.product.defaultObjectProperties[i];
                    vm.product.objectProperties.push(op);
                }
            }
            vm.isSaving = true;
            console.log(vm.product);
            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:productUpdate', result);
            MessageService.success("保存成功");
            vm.isSaving = false;
            $state.go("product");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }

        $scope.buyXml = function (line) {
            ProductXmlLines.payProductXmlLine({id:line.id},function (res) {
                if(res.status == 200){
                    console.log(res.data);
                    line = res.data;
                    MessageService.success(res.message);
                }else{
                    MessageService.error(res.message);
                }
            })
        }


        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".dialog-form").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".dialog-form").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStartDialog = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOnDialog, true);
            document.addEventListener("mouseup", moveImgEndDialog, true);
        }

        //move事件
        function moveImgOnDialog(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEndDialog() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOnDialog, true);
            document.removeEventListener("mouseup", moveImgEndDialog, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }


        /**
         * 商品模板选择
         * @type {null}
         */
        var firstMouldId = null;
        $scope.mouldChange = function () {
            // 判断是否选中了相同的参数
            if(firstMouldId!=null&&firstMouldId==vm.product.mouldId){
                return
            }

            firstMouldId=vm.product.mouldId;

            // 模板更新后，规格也更新
            vm.properties=[];
            vm.product.objectProperties = [];
            vm.product.itemValues = [];
            vm.product.defaultObjectProperties =[];
            angular.forEach(vm.moulds,function (m) {
                if(m.id==vm.product.mouldId){
                    vm.product.categoryId = m.categoryId;
                    vm.product.categoryName = m.categoryName;
                    angular.forEach(m.mouldProperties,function (ob) {
                        if(!ob.cancelled){
                            var uuid = UidService.get();
                            var objectProperty = {
                                propertyId: ob.propertyId,
                                propertyName: ob.propertyName,
                                seq: uuid,
                                uuid:uuid,
                            }
                            vm.product.defaultObjectProperties.push(objectProperty);
                            vm.properties.push(ob);
                        }
                    })
                }
            })
        }

        $scope.deleteItemValue = function (itemValue) {
            if (!!itemValue.id) {
                itemValue.cancelled = true;
                itemValue.number = UidService.get();
            } else {
                var ind = vm.product.itemValues.indexOf(itemValue);
                if (ind >= 0) {
                    vm.product.itemValues.splice(ind, 1);
                }
            }
        }

        /**
         * 规格生成弹窗中的选择框
         * @param property
         * @param value
         */
        var arrsDefault = [] ;
        $scope.valueClick = function (property, value) {
            value.selected = !value.selected;
            leadPropertiesClickStatus(vm.properties,property,value,arrsDefault)
        }

        function leadPropertiesClickStatus(allProperties,property,value,arrs) {
            // 一级规格
            if(property.propertyLead){
                angular.forEach(allProperties,function (p) {
                    angular.forEach(p.propertyValues,function (v) {
                        if(value.id==v.leadPropertyValueId){
                            v.selected = value.selected;
                        }
                    })
                })
                return
            }

            var i = 0;
            var arr = [];
            // 二级规格
            if(value.leadPropertyValueId){
                angular.forEach(allProperties,function (p) {
                    angular.forEach(p.propertyValues,function (v) {
                        if(value.leadPropertyValueId==v.id){
                            arr = v;
                        }
                        if(value.selected&&value.leadPropertyValueId==v.id){
                            v.selected = true;
                        }
                        if(value.leadPropertyValueId==v.leadPropertyValueId&&v.selected){
                            i++;
                        }
                    })
                })
            }
            if(i==0){
                arr.selected = false;
            }
        }

        /**
         * 规格生成弹窗中的 生成 按钮
         */
        $scope.propertyGener = function () {
            vm.defalutList = [];
            angular.forEach(vm.properties,function (p) {
                var obj={
                    propertyName:p.propertyName,
                    id:p.id,
                    uuid: UidService.get(),
                    propertyType:p.propertyType,
                    propertyValues:[]
                }
                angular.forEach(p.propertyValues,function (v) {
                    if(v.selected){
                        obj.propertyValues.push(v);
                    }
                })
                if(obj.propertyValues.length){
                    vm.defalutList.push(obj)
                }
            })

            if(vm.properties.length!=vm.defalutList.length){
                MessageService.error("请选取规格")
            } else{
                vm.propertyShow = !vm.propertyShow;
                $scope.propertyValueComfirm(vm.defalutList)
            }

            getProductXmls()
        }

        var items = [];
        function getProductXmls() {
            items = [
                {key: "category.id", op: "=", value: vm.product.categoryId},
                {key: "storeId", op: "=", value: vm.store.id},
                {key: "status", op: "=", value: 'Approved'}
            ];

            ProductXmls.getAllByCondition({
                items: items
            }, function (res) {
                if(res.status == 200){
                    for(var x = 0;x<vm.product.itemValues.length;x++){
                        var itemValue = vm.product.itemValues[x];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        itemValue.productXmls = angular.copy(res.data);
                        var a = [];
                        if (!!itemValue.productXmlLines) {
                            for(var i = 0;i < itemValue.productXmlLines.length;i++){
                                var line = itemValue.productXmlLines[i];
                                if(line.cancelled){
                                    continue
                                }
                                a.push(line.productXmlId);
                            }
                        }

                        var aStr = a.toString();
                        for (var i = 0, len = itemValue.productXmls.length; i < len; i++) {
                            var xml = itemValue.productXmls[i];
                            if (!!xml.modelBlendent) {
                                xml.modelBlendents = xml.modelBlendent
                            }
                            if (aStr.indexOf(xml.id) != -1) {
                                xml.choose = true;
                            }
                        }
                    }
                }else{
                    MessageService.error(res.message);
                }

            });
        }
        /**
         * 规格生成弹窗显示与隐藏
         */
        $scope.modalHide = function () {
            vm.propertyShow = !vm.propertyShow;
            // 记录勾选的规格值
            if(vm.propertyShow){
                vm.propertiesAll = angular.copy(vm.properties)||[];
            }else{
                vm.properties = angular.copy(vm.propertiesAll)||[];
            }
        }



        /**
         * 模板选择弹窗显示与隐藏
         * @param itemValue    vm.product.itemValue
         * @param line     productXmlLine
         */
        $scope.xmlOpen = function (itemValue, line) {
            vm.xmlShow = !vm.xmlShow;
            vm.currentXmlLine = line;
            var selectedId = null;
            angular.forEach(itemValue.productXmls, function (xml) {
                // 所有行置于未选择状态
                xml.select = false;
                // 若该行已被选中
                if (xml.id == line.productXmlId) {
                    vm.thisXmlLine = xml;
                    xml.modelBlendentUuid = line.modelBlendentUuid;
                    xml.select = true;
                    selectedId = xml.id;
                }
            })
            $scope.selectItemValue = itemValue;
            if (!!selectedId) {
                setTimeout(function() {
                    document.getElementById(selectedId).scrollIntoView();
                }, 100);
            }
            search();
        }

        $scope.xmlHide = function () {
            vm.xmlShow = !vm.xmlShow;
            vm.searchQuery = "";
            vm.dynamic = "";
            vm.themeId = null;
            vm.addXml = false;
            vm.currentXmlLine = {};
            $scope.selectItemValue = {};
        }

        /**
         * 选中模板文件
         * @param xml
         */
        $scope.chooseOne = function (xml) {
            xml.select = !xml.select;
            if (!vm.addXml) {
                angular.forEach($scope.selectItemValue.productXmls, function (p) {
                    if (p.id != xml.id) {
                        p.select = false;
                    }
                })
            }
        }
        /**
         * 模板文件搜索
         * @param searchQuery
         */
        function search() {
            angular.forEach($scope.selectItemValue.productXmls, function(xml){
                xml.filter = false;
            });
            if (!!vm.searchQuery) {
                angular.forEach($scope.selectItemValue.productXmls, function(xml){
                    if (!xml.filter &&  xml.name.indexOf(vm.searchQuery)<0) {
                        xml.filter = true;
                    }
                })
            }
            if (!!vm.product.productType) {
                angular.forEach($scope.selectItemValue.productXmls, function(xml){
                    if (!xml.filter &&  xml.productType!= vm.product.productType) {
                        xml.filter = true;
                    }
                })
            }
            if (!!vm.themeId) {
                angular.forEach($scope.selectItemValue.productXmls, function(xml){
                    if (!xml.filter) {
                        var _exist = false;
                        for(var i=0; i<xml.themeClassifies.length; i++){
                            if(xml.themeClassifies[i].id == vm.themeId){
                                _exist = true;
                                break
                            }
                        }
                        if(!_exist){
                            xml.filter = true;
                        }
                    }
                })
            }
            if (!!vm.dynamic) {
                var dynamic = (vm.dynamic == "true") ? true : (vm.dynamic == "false") ? false : null;
                angular.forEach($scope.selectItemValue.productXmls, function(xml){
                    if (!xml.filter && (xml.dynamic != dynamic) ) {
                        xml.filter = true;
                    }
                })
            }
        }

        $scope.xmlGener = function () {
            //编辑模板行
            vm.searchQuery= "";
            vm.dynamic = "";
            vm.themeId = null;
            if (!vm.addXml) {
                // 该行未选择则置为false
                if(vm.thisXmlLine&&!vm.thisXmlLine.select){
                    vm.thisXmlLine.choose = false;
                }
                vm.currentXmlLine.productXmlId = null;
                vm.currentXmlLine.productXmlName = null;
                angular.forEach($scope.selectItemValue.productXmls, function (p) {
                    if(p.select){
                        p.choose = true;
                        vm.currentXmlLine.name = p.name;
                        vm.currentXmlLine.productXmlId = p.id;
                        vm.currentXmlLine.productXmlName = p.name;
                        vm.currentXmlLine.productXmlNumber = p.number;
                        if (p.modelBlendents && p.modelBlendentUuid) {
                            for (var i = p.modelBlendents.length - 1; i >= 0; i--) {
                               var modelBlendent = p.modelBlendents[i];
                               if (modelBlendent.uuid == p.modelBlendentUuid) {
                                    vm.currentXmlLine.modelBlendentUuid = modelBlendent.uuid;
                                    vm.currentXmlLine.modelBlendentName = modelBlendent.name;
                                    break;
                               }
                            }
                        }else{
                            vm.currentXmlLine.modelBlendentUuid = null;
                            vm.currentXmlLine.modelBlendentName = null;
                        }
                        ProductXmls.get({id: p.id},function (item) {
                            if (item.status == 200 && item.data && item.data.documentPics.length) {
                                item.data.documentPics.sort(function(a, b){
                                    return a.seq - b.seq;
                                })
                                vm.currentXmlLine.coverImg = item.data.documentPics[0].identifier;
                            }
                        })
                    }
                })
            }else{
                //新增模板行
                if($scope.selectItemValue.productXmlLines==undefined){
                    $scope.selectItemValue.productXmlLines = [];
                }
                angular.forEach($scope.selectItemValue.productXmls, function (xml) {
                    if(xml.select){
                        xml.choose = true;
                        var xmlLine = {
                            name:xml.name,
                            seq:999,
                            productXmlId:xml.id,
                            productXmlName:xml.name,
                            uuid:UidService.get(),
                            paid:true,
                            productXmlNumber:xml.number
                        }
                        if (xml.modelBlendents && xml.modelBlendentUuid) {
                            for (var i = xml.modelBlendents.length - 1; i >= 0; i--) {
                               var modelBlendent = xml.modelBlendents[i];
                               if (modelBlendent.uuid == xml.modelBlendentUuid) {
                                    xmlLine.modelBlendentUuid = modelBlendent.uuid;
                                    xmlLine.modelBlendentName = modelBlendent.name;
                                    break;
                               }
                            }
                        }else{
                            xmlLine.modelBlendentUuid = null;
                            xmlLine.modelBlendentName = null;
                        }
                        ProductXmls.get({id: xml.id},function (item) {
                            if (item.status == 200 && item.data && item.data.documentPics.length) {
                                item.data.documentPics.sort(function(a, b){
                                    return a.seq - b.seq;
                                });
                                xmlLine.coverImg = item.data.documentPics[0].identifier;
                            }
                            $scope.selectItemValue.productXmlLines.push(xmlLine);
                        })
                    }
                })
            }
            vm.addXml = false;
            vm.xmlShow = false;
        }


        function doExchange(results, result, arr, depth, value) {
            for (var i = 0; i < arr[depth][value].length; i++) {
                result[depth] = arr[depth][value][i];
                if (depth != arr.length - 1) {
                    doExchange(results, result, arr, depth + 1, value);
                } else {
                    var t = angular.copy(result);
                    results.push(t);
                }
            }
        }

        $scope.propertyValueComfirm = function( defaultProperties) {
            var results = [];
            var result = [];
            var defaultPropertiesBack = angular.copy(defaultProperties);
            for (var i = defaultPropertiesBack.length - 1; i >= 0; i--) {
                var p =  defaultPropertiesBack[i];
               for (var j = p.propertyValues.length - 1; j >= 0; j--) {
                   var pValue = p.propertyValues[j];
                   if (!pValue.selected) {
                        p.propertyValues.splice(j,1);
                    }
               }
            }
            //确认将有多少行 ItemValue
            doExchange(results, result, defaultPropertiesBack, 0, "propertyValues");
            var itemValues = [];
            for (var i = results.length - 1; i >= 0; i--) {
                var objectvalues = results[i];
                var itemValue = {
                    objectValues: [],
                    productXmls:[]
                };
                var sku = "";
                var skuNumber = "";

                for (var j = objectvalues.length - 1; j >= 0; j--) {
                    var ob = objectvalues[j];
                    var uuid = UidService.get();
                    sku = sku + "," + ob.value;
                    skuNumber = skuNumber + "-" + ob.propertyId + "_" + ob.id;
                    var objValue = {
                        seq: uuid,
                        uuid: uuid,
                        propertyId: ob.propertyId,
                        propertyName: ob.propertyName,
                        propertyValueId: ob.id,
                        propertyValueValue: ob.value
                    }
                    itemValue.objectValues.push(objValue);
                }
                itemValue.skuNumber = skuNumber.substring(1);
                itemValue.uuid = UidService.get();
                itemValue.sku = sku.substring(1);
                itemValues.push(itemValue);
            }
            console.log(itemValues)
            results = [];
            result = [];

            //判断ItemValue  是否要加入product
            for (var i = itemValues.length - 1; i >= 0; i--) {
                var item = itemValues[i];
                var result = $scope.getItemFromProduct(item);
                if (result == null) {
                    if (vm.product.itemValues == null) {
                        vm.product.itemValues = [];
                    }
                    item.productXmlLines = [];
                    vm.product.itemValues.push(item);
                }else{
                    result.sku = item.sku;
                }
            }
        }

        $scope.getItemFromProduct = function(it){
            if (vm.product.itemValues == null || vm.product.itemValues.length == 0) {
                return null;
            }
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var item =  vm.product.itemValues[i];
                if (item.cancelled) {
                    continue;
                }
                if (it.skuNumber == item.skuNumber) {
                   return item;
                }
            }
            return null;
        }

        $scope.addXml = function (itemValue) {
            vm.addXml = true;
            vm.xmlShow = !vm.xmlShow;
            vm.themeClassifies = [];
            vm.themeId = null;
            vm.searchProductType = null;
            angular.forEach(itemValue.productXmls, function (xml) {
                // 所有行置于未选择状态
                xml.select = false;
                xml.themeClassifies.forEach(function (themeClassifie) {
                    var theme = {id:themeClassifie.id, name:themeClassifie.name};
                    var result = ListService.inList(theme, vm.themeClassifies, ["id"]);
                    if (result == null) {
                        vm.themeClassifies.push(theme);
                    }
                })
            })
            $scope.selectItemValue = itemValue;
            search();
        }
        $scope.diyEditorSettingChange = function () {
            vm.product.diyEditorSettingName = "";
            vm.diyEditorSettings.forEach(function (diyEditorSetting) {
                if(diyEditorSetting.id == vm.product.diyEditorSettingId){
                    vm.product.diyEditorSettingName = diyEditorSetting.name;
                }
            })
        }
        //关联模型
        $scope.addGltf = function () {
            $uibModal.open({
                templateUrl: 'app/entities/scene/scene-modal.html',
                controller: 'SceneModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return vm.product.moulds
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.product.moulds = result;
                }
            });
        }
        $scope.deleteMould = function (index) {
            vm.product.moulds.splice(index,1);
        }

        //产品 coverImg更改
        window.updateProjectImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.projectImg = $scope.domain + data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        $scope.themeClassifie = function (xml) {
            var _return = '';
            xml.themeClassifies.forEach(function (item) {
                _return = _return + item.name + '；'
            })
            if(_return){
                _return = _return.substring(0, _return.length - 1);
            }
            return _return;
        }
    }
})();
